import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "../support/transition"

export default class extends Controller {
  static targets = ["container", "background", "successMessage", "formContainer", "sendRequestForm"]
  static values = {
    open: { type: Boolean, default: false },
  }

  connect() {
  }

  disconnect() {
    this.close()
  }

  open() {
    this.openValue = true
    this.resetVisibility();
    this.element.classList.add('z-20');
  }

  close() {
    this.openValue = false
    if (this.hasSendRequestFormTarget) {
      this.sendRequestFormTarget.reset();
    }

  }

  closeBackground(event) {
    if (event.target === this.backgroundTarget) this.close()
  }

  async openValueChanged() {
    if (this.openValue) {
      this.containerTarget.focus()
      enter(this.backgroundTarget)
      enter(this.containerTarget)
    } else {
      leave(this.containerTarget)
      await leave(this.backgroundTarget)
      this.element.classList.remove('z-20')
    }
  }

  resetVisibility() {
    if (this.hasFormContainerTarget && this.hasSuccessMessageTarget) {
      this.formContainerTarget.classList.remove("hidden");
      this.successMessageTarget.classList.add("hidden");
    }
  }
}
