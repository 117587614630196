import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "../support/transition"

export default class extends Controller {
    static values = {
        dismissAfter: Number,
        showDelay: { type: Number, default: 0 },
        removeDelay: { type: Number, default: 1100 }
    }

    connect() {
        setTimeout(() => {
            enter(this.element)
        }, this.showDelayValue)

        // Auto dimiss if defined
        if (this.hasDismissAfterValue) {
            setTimeout(() => {
                this.close()
            }, this.dismissAfterValue)
        }
    }

    // Runs hide animation and then removes element from the page
    close() {
        leave(this.element).then(() => {
            this.element.remove()
        })

    }
}
