import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["selectField"]

    connect() {
        this.selectFieldTarget.addEventListener('change', this.updateSigningUser)
    }

    disconnect() {
        this.selectFieldTarget.removeEventListener('change', this.updateSigningUser)
    }

    updateSigningUser = (event) => {
        const documentId = event.currentTarget.dataset.autoSubmitDocumentId;
        const userId = event.target.value

        fetch(`/account/documents/${documentId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute('content') // CSRF token
            },
            body: JSON.stringify({
                document: {
                    signing_user_id: userId
                }
            })
        })
            .then(response => {
                if (response.status === 204) {
                    this.showToast();
                    return;
                }
                let contentType = response.headers.get('Content-Type');
                if (contentType && contentType.includes('application/json')) {
                    return response.json();
                } else {
                    throw new Error(`Unexpected content type: ${contentType}`);
                }
            })
            .catch(error => console.error('Error:', error));
    }

    showToast() {
        const event = new CustomEvent("showToast");
        window.dispatchEvent(event);
    }
}

