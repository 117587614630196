import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["details"];

    connect() {
        this.detailsTarget.style.display = 'none';
    }

    toggleDetails(event) {
        this.detailsTarget.style.display = event.target.value === 'Yes' ? 'block' : 'none';
    }
}