import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "menu"];
  toggle() {
    this.menuTarget.classList.toggle("hidden");
    this.buttonTarget.setAttribute(
      "aria-expanded",
      this.menuTarget.classList.contains("hidden") ? "false" : "true"
    );
  }
}
