import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["SearchInput", "vendorDetail", "emptyState", "vendorItem", "previewName", "previewLogo", "previewUrl", "previewAddress", "previewPurpose", "previewContact", "previewButtonLink"];

    search() {
        const searchTerm = this.targets.find("searchInput").value;
        const searchPath = this.targets.find('searchInput').getAttribute('data-search-path');
        const teamId = this.targets.find('teamId').getAttribute('data-team-id');

        fetch(`${searchPath}?search=${searchTerm}`, {
            headers: { "Accept": "application/json" }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.updateVendorList(data, teamId);
            })
            .catch(error => {
                console.error('Fetch error:', error);
            });
    }

    updateVendorList(vendors, teamId) {
        const options = document.getElementById('options');
        const searchResults = document.getElementById('searchResults');
        const noResultsDiv = document.getElementById('noResults');

        // Clear existing vendor list first
        searchResults.innerHTML = "";

        // If there are results
        if (vendors.length > 0) {
            noResultsDiv.classList.add('hidden');
            options.classList.remove('hidden');
            this.emptyStateTarget.classList.remove('hidden');
            this.vendorDetailTarget.classList.add('hidden');

            vendors.forEach(vendor => {
                const listItem = `
                    <li data-action="click->vendors#showDetails"
                        id="clickTarget_${vendor.id}"
                        data-id="${vendor.id}"
                        data-name="${vendor.name}"
                        data-url="${vendor.url}"
                        data-address="${vendor.address}"
                        data-purpose="${vendor.purpose}"
                        data-contact="${vendor.contact}"
                        data-path="/account/teams/${teamId}/subprocessors/from_vendor?vendor_id=${vendor.id}"
                        class="group flex cursor-pointer select-none items-center rounded-md p-2 hover:bg-gray-200">
                        <img src="https://logo.clearbit.com/${vendor.url}" alt="" class="h-6 w-6 flex-none rounded-full">
                            <span class="ml-3 flex-auto truncate">${vendor.name}</span>
                            <svg class="group-hover:block ml-3 hidden h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                            </svg>
                    </li>`;
                searchResults.innerHTML += listItem;  // append to searchResults container
            });
        } else {
            noResultsDiv.classList.remove('hidden');
            options.classList.add('hidden');
        }
    }


    showDetails(event) {
        let element = event.target;

        // If the clicked element is not the list item, find its closest ancestor which is
        while (!element.hasAttribute('data-id') && element.parentElement) {
            element = element.parentElement;
        }

        const dataset = element.dataset;

        this.previewLogoTarget.src = `https://logo.clearbit.com/${dataset.url}`;
        this.previewNameTarget.textContent = dataset.name;
        this.previewUrlTarget.textContent = dataset.url;
        this.previewAddressTarget.textContent = dataset.address;
        this.previewPurposeTarget.textContent = dataset.purpose;
        this.previewContactTarget.textContent = dataset.contact;
        this.previewButtonLinkTarget.href = dataset.path;

        this.emptyStateTarget.classList.add('hidden');
        this.vendorDetailTarget.classList.remove('hidden');
    }

}
