import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["toastElement"]

    connect() {
        this.element.classList.add('opacity-0')
        window.addEventListener('showToast', () => {
            this.show();
        });
    }

    show() {
        this.element.classList.remove('translate-y-2', 'opacity-0', 'sm:translate-x-0', 'sm:translate-y-2');
        this.element.classList.add('translate-x-0', 'sm:translate-y-0', 'opacity-100', 'transition-transform', 'ease-in', 'duration-100', 'transition-opacity', 'ease-in', 'duration-100');

        setTimeout(() => {
            // Revert back to hidden after 3 seconds
            this.element.classList.remove('translate-y-0', 'sm:translate-x-0', 'opacity-100', 'transition-transform', 'ease-in', 'duration-100', 'transition-opacity', 'ease-in', 'duration-100');
            this.element.classList.add('translate-y-2', 'opacity-0', 'sm:translate-y-0', 'sm:translate-y-2');
        }, 3000);
    }
}
