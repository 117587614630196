import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "source", "button", "text"  ]
    copy() {
        navigator.clipboard.writeText(this.sourceTarget.value).then(() => {
            this.buttonTarget.classList.remove('bg-gray-700');
            this.buttonTarget.classList.remove('hover:bg-gray-300');
            this.buttonTarget.classList.remove('hover:text-gray-800');
            this.buttonTarget.classList.add('bg-green-600');
            this.textTarget.innerText = "Copied!";
            setTimeout(() => {
                this.buttonTarget.classList.remove('bg-green-600');
                this.buttonTarget.classList.add('bg-gray-700');
                this.buttonTarget.classList.add('hover:bg-gray-300');
                this.buttonTarget.classList.add('hover:text-gray-800');
                this.textTarget.innerText = "Copy URL";
            }, 2000);
        });
    }
}