import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
    }

    showSuccessMessage() {
        // Hide the form
        document.querySelector("#formContainer").classList.add("hidden");

        // Show the success message
        document.querySelector("#successMessageContainer").classList.remove("hidden");
    }
}