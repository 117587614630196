import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["menu"];
  
    toggle(event) {
      event.preventDefault();
      this.menuTarget.classList.toggle("hidden");
  
      if (!this.menuTarget.classList.contains("hidden")) {
        this.menuTarget.classList.remove("transform", "opacity-0", "scale-95");
        this.menuTarget.classList.add("transform", "opacity-100", "scale-100");
      } else {
        this.menuTarget.classList.remove("transform", "opacity-100", "scale-100");
        this.menuTarget.classList.add("transform", "opacity-0", "scale-95");
      }
    }
  }