import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["emailInput", "emailField"];

    showEmailInput() {
        if (this.emailInputTarget.classList.contains("hidden")) {
            this.emailInputTarget.classList.remove("hidden");
        } else {
            this.emailInputTarget.classList.add("hidden");
            this.emailFieldTarget.value = '';
        }
    }
}